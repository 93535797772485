import {
  Box,
  Button,
  FormControl,
  Grid,
  RadioGroup,
  Stack,
  ToggleButton,
  styled,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "Redux/App/hooks";
import { useTimer } from "hooks/useTimer";
import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage } from "react-intl";
import { subtractTimes } from "../../../utils/helperfunctions/getquestiontimevalue";
import { Check, Close } from "@mui/icons-material";
import { ReactComponent as CollapseIcon } from "../../../assets/images/collapse.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import TimerIcon from "../../../assets/images/timer.svg";
import QuestionAttemptAndCheck from "./QuestionAttemptAndCheck";
import DescriptionBox from "../DescriptionBox";
import { ReactComponent as ViewColumn } from "../../../assets/images/viewcolumnsIcon.svg";
import {
  GetAllSubmittedQuestion,
  GetAllSubmittedQuestionForOnlineExam,
  setIsTimer,
  setProgressBarNavigation,
  setQuizQuestionComplete,
  setRemainTime,
  setRemainTimeQuiz,
  setRemainTimeSeries,
  setStudentLevel,
  SubmitQuestionsAns,
  SubmitQuestionsAnsForOnlineExam,
  updateActiveQuestion,
} from "Redux/feature/Questions";
import { ReactComponent as WhiteTimerIcon } from "../../../assets/images/WhiteTimerIcon.svg";
import { useWirisParser } from "hooks/useWiris";
import {
  CompleteContainerAtomic,
  FetchAtomicAndContainerData,
  setAssignmentData,
  setActiveIndex,
} from "Redux/feature/StudentHome";
import { getImageUrl } from "hooks/imageUrl";
import { getCookie } from "views/common/cookieUtils";
import Loader from "views/common/loader";

type Props = {
  question: string;
  onSubmit: Function;
  option_images: any;
  options: [];
  image: {
    file_name: string;
    file_path: string;
    explanation_image: any;
  };
  question_id: any;
  timesPerQuestion: any;
  collection: any;
  solving_explanation: any;
  showResultMode: boolean;
  isModalOpen?: any;
  onClose?: any;
  horizontal?: number;
  setIsCollapsed2?: any;
  explanation_image?: any;
  score?: string;
};
const QuestionOption = styled(ToggleButton)(({ theme }) => ({
  borderRadius: 50,
  width: "30px",
  height: "30px",
  fontSize: "14px",
  color: theme.palette.primary.main,
  marginRight: "10px",
  marginLeft: "15px",
  border: `1px solid ${theme.palette.primary.main}`,
  transition: "all 0.1s ease",
  "&.Mui-selected": {
    color: "white",
    backgroundColor: theme.palette.primary.main,
  },
  "&:hover": {
    color: "white",
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
  "&:focus": {
    color: "white",
    backgroundColor: `${theme.palette.primary.main} !important`,
  },
}));
const TextImageWithQuestion = ({
  question,
  option_images,
  options,
  image,
  onSubmit,
  question_id,
  collection,
  timesPerQuestion,
  solving_explanation,
  showResultMode,
  horizontal,
  isModalOpen,
  onClose,
  setIsCollapsed2,
  explanation_image,
  score,
}: Props) => {
  const { questions, container_data }: any = useAppSelector(
    (state) => state.Questions
  );

  const dispatch = useAppDispatch();
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [user_id, setUserId] = useState<any>();
  const navigate = useNavigate();
  const [showTime, setShowTime] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState<any>([]);
  const [searchParams, setSerchParams] = useSearchParams();
  const chapter_type = searchParams.get("chapter_type")
  let userId = getCookie("id");
  const [option_id, setOptionId] = useState<any>();
  const [questionModal, setQuestionModal] = useState<boolean>(false);
  const [renderImg, setRenderImg] = useState<any>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const assignment_id = searchParams.get("assignment_id");
  const containerID = searchParams.get("container_id");
  const is_cluster = searchParams.get("is_cluster");
  const remianTime = useAppSelector((state) => state.Questions.remainTime);
  const renderPreview: any = useAppSelector(
    (state) => state.Questions.renderPreview
  );
  const remainingTime: any = useAppSelector(
    (state) => state.Questions.remainTime
  );
  const remainingTimeQuiz: any = useAppSelector(
    (state) => state.Questions.remainTimeQuiz
  );
  const remainingTimeSeries: any = useAppSelector(
    (state) => state.Questions.remainTimeSeries
  );
  const notSubmitted = useAppSelector(
    (state) => state.Questions.notSubmitted
  );
  const defaultCourseId = getCookie("default_course_id");
  let timer = timesPerQuestion;
  const time = useTimer(timer);
  const isSmallScreen = window.innerWidth <= 768;
  const filePath = collection[0]?.file ? collection[0].file.file_path : "";
  const addQuestionData = (questionId: any, optionId: any) => {
    const questionData = getQuestionData();

    const existingIndex = questionData.findIndex(
      (item: any) => item.questionId == questionId
    );

    if (existingIndex !== -1) {
      questionData[existingIndex].optionId = optionId;
    } else {
      questionData.push({ questionId, optionId });
    }

    localStorage.setItem("questionData", JSON.stringify(questionData));
  };
  const activeQuestion = useAppSelector(
    (state) => state.Questions.activeQuestion
  );
  const ActiveQuestionDifficultyLevel = useAppSelector(
    (state) => state.Questions?.questions[activeQuestion]?.difficulty_level
  );
  const handleSelectOption = (e: any) => {
    setOptionId(e);
    if (
      searchParams.get("container_type_id") == "2" || (searchParams.get("container_type_id") == "7"&&is_cluster)||
      searchParams.get("container_type_id") == "6" || ((searchParams.get("container_type_id") == "10" || searchParams.get("container_type_id") == "11") && chapter_type=="2" )
    ) {
      addQuestionData(question_id, e);
      const isOnlineExam =
      searchParams.get("container_type_id") == "7" && is_cluster;
      
    const actionToDispatch = isOnlineExam
      ?    SubmitQuestionsAnsForOnlineExam({
        student_id: user_id,
        practice_id: allQuestionContainerID,
        question_id: question_id,
        collection_id: "",
        answer: e,
        is_skipped_question: 0,
        notes: "",
        level: ActiveQuestionDifficultyLevel,
        time_taken_for_answer: differenceValue,
        onlineExamType: "practice",
        course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        selectOption:1
      })

      : SubmitQuestionsAns({
          user_id: user_id,
          container_id:
            searchParams.get("container_type_id") == "10" ||
            searchParams.get("container_type_id") == "11"
              ? container_data.id
              : searchParams.get("container_id"),
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          question_id: question_id,
          collection_id: "",
          answer: e,
          is_skipped_question: 0,
          notes: "",
          selectOption:1,
          time_taken_for_answer: differenceValue,
          ...((searchParams.get("container_type_id") == "10" ||
            searchParams.get("container_type_id") == "11") && {
            type: "Online Exam",
            assignment_id: assignment_id,
            main_container_id: searchParams.get("container_id"),
          }),
        });
      dispatch(
          actionToDispatch).then((res)=>{ 
        if( searchParams.get("container_type_id") == "6"){
          const remainingTime = subtractTimes(
            remainingTimeQuiz,
            differenceValue        );
        dispatch(setRemainTimeQuiz(remainingTime))
        }else if(searchParams.get("container_type_id") == "10" || searchParams.get("container_type_id") == "11"){
          const remainingTime = subtractTimes(
            remianTime,
            differenceValue          );
          dispatch(setRemainTime(remainingTime));
          dispatch(setStudentLevel(res?.payload?.student_level));
        }
        else{
          const remainingTimeSeriesTotal = subtractTimes(
            remainingTimeSeries,
            differenceValue        );
        dispatch(setRemainTimeSeries(remainingTimeSeriesTotal))

        }
      })
    }
  };
  const totalExamTime: any = useAppSelector(
    (state) => state.Questions.totalExamTime
  );
  let selectedCourse: any = useAppSelector(
    (state) => state.BoardExam.selectedCourse
  );
  const [textColor, setTextColor] = useState<string>();
  const [color, setColor] = useState<string>();
  const handleTextColor = () => {
    let id: any = searchParams.get("container_type_id");
    if (id == 1 && isAllQuestionAttempt === false) {
      setTextColor("black");
    } else if (id == 2 && isAllQuestionAttempt === false) {
      setTextColor("black");
    } else if (id == 3 && isAllQuestionAttempt === false) {
      setTextColor("black");
    } else if (id == 6 && isAllQuestionAttempt === false) {
      setTextColor("white");
    } else if (id == 7 && isAllQuestionAttempt === false) {
      setTextColor("black");
    } else if (id == 8 && isAllQuestionAttempt === false) {
      setTextColor("#black");
    } else if (id == 10 && isAllQuestionAttempt === false) {
      setTextColor("white");
    }
    else if (id == 11 && isAllQuestionAttempt === false) {
      setTextColor("white");
    } else {
      setTextColor("black");
    }
  };
  const handleColor = () => {
    let id: any = searchParams.get("container_type_id");
    if (id == 1 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 2 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 3 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 6 && isAllQuestionAttempt === false) {
      setColor("white");
    } else if (id == 7 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 8 && isAllQuestionAttempt === false) {
      setColor("#878E95");
    } else if (id == 10 && isAllQuestionAttempt === false) {
      setColor("white");
    } else if (id == 11 && isAllQuestionAttempt === false) {
      setColor("white");
    }else {
      setColor("#878E95");
    }
  };

  let differenceValue: any;
  const containerId: any = searchParams.get("container_type_id");
  const atomicSideBar: any = searchParams.get("atomicSideBar");
  if (
    (containerId && parseInt(containerId) === 1 && container_data) ||
    (containerId && parseInt(containerId) === 7&&!is_cluster && container_data) ||
    (containerId && parseInt(containerId) === 8 && container_data)
  ) {
    differenceValue = subtractTimes(timesPerQuestion, time);
  } else if (containerId && (parseInt(containerId) === 10||parseInt(containerId) === 11) && container_data) {
    differenceValue = subtractTimes(remainingTime, timesPerQuestion);
  }else if (containerId && parseInt(containerId) === 6 && container_data) {
    differenceValue = subtractTimes(remainingTimeQuiz, timesPerQuestion);
  }   else if (containerId &&( parseInt(containerId) === 2||parseInt(containerId) === 7 &&is_cluster) && container_data) {
    differenceValue = subtractTimes(remainingTimeSeries, timesPerQuestion);
  }  else {
    differenceValue = subtractTimes(totalExamTime, timesPerQuestion);
  }
  const isAllQuestionAttempt = useAppSelector(
    (state) => state.Questions.isAllQuestionAttempt
  );

  const fetchAllSubmittedQuestionAnswer: any = useAppSelector(
    (state) => state.Questions.fetchAllSubmittedQuestions
  );
  const questionDetail = fetchAllSubmittedQuestionAnswer?.find((q: { question_id: any; }) => q.question_id === question_id);

  const currentQuestion = activeQuestion + 1;
  const handleClosePopup = () => {
    setQuestionModal(false);
  };
  
  const allQuestionContainerID = useAppSelector(
    (state) => state.OnlinePracticeExamSlice?.saveOnlinePracticeInfo.id
  );
  let atomicUnitData: any = useAppSelector(
    (state) => state.StudentsHomeData.atomicUnitsData
  );
  const student_level = useAppSelector(
    (state) => state?.Questions?.student_level
  );
  const handleIconClick = () => {
    setIsCollapsed((prevState) => !prevState);
    setIsCollapsed2(!isCollapsed);
  };
  useEffect(() => {
    const handleResize = () => {
      setIsCollapsed(window.innerWidth < 900 ? false : true);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const handleTimerIconClick = () => {
    setShowTime(!showTime);
    let id: any = searchParams.get("container_type_id");
    if (id === "1" || id === "2" || id === "3" || id === "7" || id === "8") {
      if (!isAllQuestionAttempt) {
        setRenderImg(true);
      } else {
        setRenderImg(false);
      }
    }
  };

  useEffect(() => {
    if ((searchParams.get("container_type_id") == "10"||searchParams.get("container_type_id") == "11")&&isAllQuestionAttempt === false) {
      setColor("white");
    }
  }, [searchParams.get("container_type_id") == "10",searchParams.get("container_type_id") == "11",isAllQuestionAttempt]);
  useEffect(() => {
    setUserId(userId);
    handleTextColor();
    handleTimerIconClick();
    handleColor();
  }, [userId]);
  useEffect(() => {
    if (isAllQuestionAttempt === true) {
      handleRadioCheck();
    }

    setUserId(userId);
  }, [fetchAllSubmittedQuestionAnswer]);

  useEffect(() => {
    const filteredIds = questions
      .filter(
        (obj: any) =>
          [4, 7, 3].includes(obj.type.id) && obj.collections.length > 0
      )
      .map((obj: any) => obj.id);

    const isMatch = filteredIds.length > 0 && filteredIds[0] === question_id;
    setIsDialogOpen(isMatch);
  }, [questions, setIsDialogOpen, question_id]);

  const handleOpen = () => {
    setQuestionModal(true);
  };
  const handleTimeClick = () => {
    setShowTime(!showTime);
  };

  useEffect(() => {
    if (containerId === "1" && time === "00:00") {
      dispatch(
        SubmitQuestionsAns({
          user_id: userId,
          container_id: searchParams.get("container_id"),
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          question_id: question_id,
          collection_id: "",
          explanation_image: "",
          answer: "",
          is_skipped_question: 1,
          notes: "",
          time_taken_for_answer: differenceValue,
        })
      ).then((res: any) => {
        dispatch(
          GetAllSubmittedQuestion({
            user_id: userId,
            container_id: searchParams.get("container_id"),
          })
        );
        dispatch(updateActiveQuestion(activeQuestion));
        dispatch(setQuizQuestionComplete(true));
      });
    }
  }, [differenceValue]);
  const getQuestionData = () => {
    const storedData = localStorage.getItem("questionData");
    return storedData ? JSON.parse(storedData) : [];
  };
  useEffect(() => {
    if ((containerId == "2" || containerId == "6"||(containerId=="7"&&is_cluster) ||((containerId=="10" || containerId == "11") &&chapter_type=="2")) && !isAllQuestionAttempt) {
      const allQuestionData = getQuestionData();
      const combinedArray = options.map((firstObj: { question_id: any }) => {
        const questionId = firstObj.question_id;
        const matchingOption = allQuestionData.find(
          (secondObj: { questionId: any }) =>
            secondObj.questionId === questionId
        );
        setOptionId(matchingOption?.optionId);

        return {
          question_id: questionId,
          option_id: matchingOption ? matchingOption.optionId : null,
        };
      });
    }
  }, [containerId == "2", containerId == "6",containerId=="10", containerId == "11", chapter_type=="2", !isAllQuestionAttempt,containerId=="7",is_cluster]);

  useEffect(() => {
    if ((containerId === "10"||containerId=="11") && time === "00:00") {
      dispatch(
        SubmitQuestionsAns({
          user_id: userId,
          question_id: question_id,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          collection_id: "",
          answer: "",
          is_skipped_question: 1,
          notes: "",
          time_taken_for_answer: differenceValue,
          container_id: container_data.id,
          main_container_id: containerID,
          assignment_id: assignment_id,
          type: "Online Exam",
        })
      ).then((res: any) => {
        if(res?.payload?.status==true){
          dispatch(setIsTimer(true));
        }
      });
    }
  }, [time]);

  useEffect(() => {
    if ((containerId === "7"&&!is_cluster || containerId === "8") && time === "00:00") {
      dispatch(
        SubmitQuestionsAnsForOnlineExam({
          student_id: userId,
          practice_id: allQuestionContainerID,
          question_id: question_id,
          collection_id: "",
          answer: "",
          explanation_image: "",
          is_skipped_question: 1,
          notes: "",
          time_taken_for_answer: differenceValue,
          level: ActiveQuestionDifficultyLevel,
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
        })
      ).then((res: any) => {
        dispatch(setProgressBarNavigation(true));
        dispatch(
          GetAllSubmittedQuestionForOnlineExam({
            student_id: userId,
            practice_id: allQuestionContainerID,
            question_id: question_id,
            collection_id: "",
            answer: "",
            explanation_image: "",
            is_skipped_question: 1,
            notes: "",
            time_taken_for_answer: differenceValue,
            level: ActiveQuestionDifficultyLevel,
          })
        );
        dispatch(updateActiveQuestion(activeQuestion));
        dispatch(setQuizQuestionComplete(true));
      });
    }
    if (containerId === "7" || containerId === "8") {
      dispatch(setProgressBarNavigation(false));
    }
  }, [differenceValue]);
  useEffect(() => {
    if ((containerId === "7"&&is_cluster) && time === "00:00") {
        dispatch(
          SubmitQuestionsAnsForOnlineExam({
            student_id: userId,
            practice_id: allQuestionContainerID,
            question_id: question_id,
            collection_id: "",
            answer: "",
            explanation_image: "",
            is_skipped_question: 1,
            notes: "",
            time_taken_for_answer: differenceValue,
            level: ActiveQuestionDifficultyLevel,
            course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          })
        ).then((res: any) => {
          navigate(
            `/practice-summary?container_type_id=${searchParams.get(
              "container_type_id"
            )}&container_id=${allQuestionContainerID}`
          );
      });
    }
  }, [time]);
  useEffect(() => {
    if ((containerId === "6" || containerId === "2") && time === "00:00") {
      dispatch(
        SubmitQuestionsAns({
          user_id: userId,
          container_id: searchParams.get("container_id"),
          course_id: defaultCourseId ? defaultCourseId : selectedCourse,
          question_id: question_id,
          collection_id: "",
          answer: "",
          is_skipped_question: 1,
          notes: "",
          time_taken_for_answer: differenceValue,
        })
      ).then((res: any) => {
        const meetingIndex = atomicUnitData?.findIndex((item: any) => {
          return item.meeting.assignment.some(
            (assignmentData: any) => assignmentData.id == assignment_id
          );
        });
        if (userId) {
          dispatch(
            CompleteContainerAtomic({
              student_id: userId,
              assignment_id: searchParams.get("assignment_id"),
              meeting_id: localStorage.getItem("meetingId")
                ? localStorage.getItem("meetingId")
                : atomicUnitData[meetingIndex]?.meeting?.id,
              course_id: defaultCourseId ? defaultCourseId : selectedCourse,

              type: "container",
              id: searchParams.get("container_id"),
            })
          ).then((resp: any) => {
            dispatch(
              FetchAtomicAndContainerData({
                id: userId ? userId : null,
                course_id: defaultCourseId ? defaultCourseId : selectedCourse,
              })
            );
            navigate(
              `/practice-summary?container_type_id=${searchParams.get(
                "container_type_id"
              )}&container_id=${searchParams.get(
                "container_id"
              )}&assignment_id=${searchParams.get("assignment_id")}`
            );
          });
        }
      });
    }
  }, [time]);
  const handleRadioCheck = () => {
    const arrayOfIds = option_images.map((obj: any) => obj.id.toString());
    let ans: any = [];
    fetchAllSubmittedQuestionAnswer.forEach((e: any) => {
      if (arrayOfIds.includes(e.answer)) {
        ans.push(e);
      }
    });

    setCorrectAnswer(ans);
  };
  let lastIndex = questions.length - 1;

  const questionRef = useRef(null);
  const questionDialogRef: React.MutableRefObject<any> = useRef(null);
  useWirisParser(questionRef);

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const nextQuestion = () => {
    if (searchParams.get("container_type_id") === "7") {
      navigate(`/student/library`, {
        state: { fromQuestionPageOfNewPractice: true },
      });
    } else if (searchParams.get("container_type_id") === "8") {
      navigate(`/student/library`, {
        state: { fromQuestionPageOfTheOven: true },
      });
    } else {
      const meetingIndex = atomicUnitData.findIndex((item: any) => {
        return item.meeting.assignment.some(
          (assignmentData: any) => assignmentData.id == assignment_id
        );
      });

      const currentIndexASS = atomicUnitData[
        meetingIndex
      ]?.meeting?.assignment?.findIndex((item1: any) => {
        return item1.id == searchParams.get("assignment_id");
      });

      const nextElementASS =
        atomicUnitData[meetingIndex]?.meeting?.assignment[currentIndexASS + 1];
      if (nextElementASS) {
        var jsonString = JSON.stringify(nextElementASS?.atomics_containers);
        localStorage.setItem("assignment", jsonString);
        dispatch(setAssignmentData(nextElementASS?.atomics_containers));
        dispatch(setActiveIndex(0));
        if (nextElementASS?.atomics_containers[0]?.types == "container") {
          const examTime =
            nextElementASS?.atomics_containers[0]?.container[0]
              ?.container_duration;

          const containerUrl = `/question-pages?container_type_id=${nextElementASS?.atomics_containers[0]?.container[0]?.container_type_id}&container_id=${nextElementASS?.atomics_containers[0]?.atomic_container_id}&assignment_id=${nextElementASS?.atomics_containers[0]?.assignment_id}`;

          const state = { examTime };
          navigate(containerUrl, { state });
        } else {
          const atomicType =
            nextElementASS?.atomics_containers[0]?.atomic[0]?.types.name;
          const assignmentId =
            nextElementASS?.atomics_containers[0]?.assignment_id;
          const link = nextElementASS?.atomics_containers[0]?.atomic[0]?.link;
          const atomic_id =
            nextElementASS?.atomics_containers[0]?.atomic_container_id;

          const url = `/question-pages?atomic_type=${atomicType}&atomic_id=${atomic_id}&assignment_id=${assignmentId}&atomicLibrary=${true}`;

          const state = { link };
          navigate(url, { state });
        }
      } else {
        navigate("/student-home");
      }
    }
  };
  const text = collection[0]?.type?.name;
  const textLength = text ? text.length : 0;

  return (
    <>
      {/* {notSubmitted&& <Loader />} */}
      <Grid
        xs={12}
        sm={6}
        sx={{
          display: "flex",
          margin: "auto",
          width: "calc(100% - 15px)",
          justifyContent: "center",
          flexDirection: { xs: "column", sm: "column", md: "row-reverse" },
          gap: "10px",
          alignItems: { xs: "center", sm: "center", md: "unset" },
        }}
        className="custom-container"
      >
        {" "}
        {isAllQuestionAttempt === true &&
          (containerId === "7" || containerId === "8") && (
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                width: "95%",
                marginBottom: "20px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                    color: "black",
                    marginLeft: "75px",
                  }}
                >
                  <FormattedMessage id="insight-question-title" />
                </Typography>
              </Box>
              <Box>
                {" "}
                <Box
                  onClick={handleTimeClick}
                  className={`${
                    activeQuestion == 0
                      ? "question-option-2 question-option-mobile-2"
                      : ""
                  }`}
                  sx={{
                    border: "1px solid #3397DB",
                    fontWeight: "600",
                    borderRadius: { xs: "5px", md: "20px" },
                    padding: "5px 15px 5px 15px",
                    display: "flex",
                    alignItems: "flex-end",
                    color:
                      containerId === "6" || containerId === "10"||containerId==="11"
                        ? "black"
                        : "#3397DB",
                    backgroundColor: "#F2F8FD",
                    fontSize: "14px",
                    lineHeight: "19px",
                    cursor: "pointer",
                    position: "absolute",
                    top: {
                      xs:
                        containerId === "7" || containerId === "8"
                          ? "22px"
                          : "71px",
                      md: "71px",
                    },
                    left: "15px",
                  }}
                >
                  {time}
                </Box>{" "}
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: color,
                    marginLeft: "75px",
                  }}
                >
                  <FormattedMessage id="practice-time" />
                </Typography>
              </Box>
            </Box>
          )}
        <Box
          sx={{
            maxWidth: "724px",
            width: { xs: "100%", md: "95%" },
            height: "100%",
            flexDirection: "column",
            gap: "15px",
            display: "flex",
            position: "relative",
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              display:
                containerId === "6" || containerId === "10"||containerId==="11" ? "none" : "flex",

              justifyContent: "space-between",
              flexDirection: "row-reverse",
              alignItems:
                containerId === "7" || containerId === "8"
                  ? "flex-start"
                  : "flex-end",
              height: {
                xs:
                  containerId === "7" || containerId === "8" ? "30px" : "75px",
                md: "75px",
              },
              marginBottom: {
                xs: containerId === "7" || containerId === "8" ? "8px" : "0px",
                md: "0px",
              },
              paddingBottom: isAllQuestionAttempt === true ? "10px" : "",
              borderBottom:
                isAllQuestionAttempt === true ? "1px solid #E6E6E6" : "",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: {
                  xs:
                    containerId === "7" || containerId === "8"
                      ? "-5px"
                      : "43px",
                  md: "43px",
                },
                left: {
                  xs: containerId === "7" || containerId === "8" ? "30px" : "",
                  md: "0px",
                },
              }}
            >
              {questions[activeQuestion]?.question[activeQuestion].length >
              100 ? (
                <Button onClick={handleOpen}>
                  <ViewColumn />
                </Button>
              ) : (
                <></>
              )}
              {isAllQuestionAttempt === true &&
              questions[activeQuestion]?.solving_explanation.length > 100 ? (
                <Button onClick={handleOpen}>
                  <ViewColumn />
                </Button>
              ) : (
                <></>
              )}
            </Box>
            {isAllQuestionAttempt === true ? (
              <></>
            ) : (
              <>
                <Box sx={{ position: "absolute", top: "-23px", left: "-32px" }}>
                  {showTime ? (
                    <Box
                      onClick={handleTimeClick}
                      className={`${
                        activeQuestion == 0
                          ? "question-option-2 question-option-mobile-2"
                          : ""
                      }`}
                      sx={{
                        border: "1px solid #3397DB",
                        fontWeight: "600",
                        borderRadius: { xs: "5px", md: "20px" },
                        padding: "5px 15px 5px 15px",
                        display: "flex",
                        alignItems: "flex-end",
                        color: "#3397DB",
                        backgroundColor: "#F2F8FD",
                        fontSize: "14px",
                        lineHeight: "19px",
                        cursor: "pointer",
                        position: "absolute",
                        top: {
                          xs:
                            containerId === "7" || containerId === "8"
                              ? "22px"
                              : "71px",
                          md: "71px",
                        },
                        left: "30px",
                      }}
                    >
                      {time}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 0,
                      }}
                    >
                      {renderImg === true ? (
                        <Box
                          sx={{
                            cursor: "pointer",
                            position: "absolute",
                            top:
                              containerId === "7"
                                ? { xs: "27px", md: "78px" }
                                : containerId === "1"
                                ? { xs: "22px", md: "78px" }
                                : "78px",
                            left: "64px",
                            height: "20px",
                            width: "20px",
                          }}
                        >
                          <img
                            className="library-item-img"
                            src={TimerIcon}
                            alt="TimerIcon"
                            onClick={handleTimerIconClick}
                          />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            cursor: "pointer",
                            position: "absolute",
                            top:
                              containerId === "7"
                                ? { xs: "27px", md: "78px" }
                                : containerId === "1"
                                ? { xs: "22px", md: "78px" }
                                : "78px",
                            left: "64px",
                            height: "20px",
                            width: "20px",
                          }}
                        >
                          <WhiteTimerIcon onClick={handleTimerIconClick} />
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
              </>
            )}

            <Box
              sx={{
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: {
                  xs:
                    containerId === "7" || containerId === "8"
                      ? "flex-start"
                      : "flex-end",
                  md: "flex-end",
                },
                gap: "7.5px",
                marginLeft: "74px",
              }}
            >
              {isAllQuestionAttempt === true ? (
                <></>
              ) : (
                <>
                  {containerId != "1" && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: color,
                      }}
                    >
                      {containerId == "7" || containerId == "8" ? (
                        <></>
                      ) : (
                        <FormattedMessage id="practice-time" />
                      )}{" "}
                    </Typography>
                  )}
                </>
              )}

              {isAllQuestionAttempt === true ? (
                <></>
              ) : (
                <>
                  {containerId != "1" && (
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 600,
                        color: textColor,
                      }}
                    >
                      {containerId == "7" || containerId == "8" ? (
                        <></>
                      ) : (
                        <b>
                          {containerId == "1"
                            ? timesPerQuestion
                            : container_data.total_examtime}
                        </b>
                      )}{" "}
                    </Typography>
                  )}
                </>
              )}

              {isAllQuestionAttempt === true ? (
                <></>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontSize: {
                        xs:
                          containerId == "7" || containerId == "8"
                            ? "16px"
                            : "14px",
                      },
                      fontWeight: 400,
                      color: color,
                    }}
                  >
                    <FormattedMessage id="exam-time" />
                    {(containerId == "7" || containerId == "8") && <>:</>}
                  </Typography>
                </>
              )}
            </Box>
            <Box
              sx={{
                display: "block",
                alignItems: {
                  xs:
                    containerId === "7" || containerId === "8"
                      ? "flex-start"
                      : "flex-end",
                  md: "flex-end",
                },
                marginTop: "0",
              }}
            >
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: {
                    xs:
                      containerId === "7" || containerId === "8"
                        ? "600"
                        : "400",
                    md:
                      containerId === "7" || containerId === "8"
                        ? "400"
                        : "400",
                  },
                  textAlign: { xs: "center", md: "right" },
                  color: {
                    xs:
                      containerId === "7" || containerId === "8"
                        ? "#353A3F"
                        : color,
                    md: color,
                  },
                  ...(containerId == 1 || containerId == 2
                    ? {
                        height: "75px",
                        display: "flex",
                        alignItems: "flex-end",
                      }
                    : {}),
                  ...(containerId == 8 || containerId == 7
                    ? {
                        height: "75px",
                        display: "flex",
                        alignItems: "flex-end",
                      }
                    : {}),
                }}
              >
                <FormattedMessage
                  id="questionInfo"
                  values={{
                    totalQuestions: questions.length,
                    currentQuestion,
                  }}
                />
              </Typography>
            </Box>
          </Box>

          {/* containerId 6  */}
          <Box
            sx={{
              display: {
                xs:
                  (containerId === "6" || containerId === "10"||containerId=="11") &&
                  isAllQuestionAttempt === true
                    ? "flex"
                    : "none",
                md: "none",
              },
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "column",
              width:
                containerId === "6" || containerId === "10" ||containerId==="11"? "95%" : "100%",
              margin:
                containerId === "6" || containerId === "10"||containerId==="11" ? "0 auto " : 0,
              marginTop:
                containerId === "6" || containerId === "10" ||containerId==="11"? "15px" : 0,
              height: "100%",
              gap: "15px",
            }}
          >
            <Box
              sx={{
                fontSize: "24px",
                fontWeight: 700,
                color: "#222529",
              }}
            >
              {" "}
              {containerId == "6" || containerId == "10"||containerId=="11" ? (
                container_data?.display_name
              ) : (
                <FormattedMessage id="examines-analogies" />
              )}
              {/* {(containerId == "10"||containerId=="11") && isAllQuestionAttempt == false && (
                <>({student_level})</>
              )} */}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Box>
                <FormattedMessage
                  id="questionInfo"
                  values={{
                    totalQuestions: questions.length,
                    currentQuestion,
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {" "}
                <>
                {
    containerId == "6" ? 
    <FormattedMessage id="answer-question" /> : 
    <FormattedMessage id="exam-time" />
}:
                  <Box
                    onClick={handleTimeClick}
                    className={`${
                      activeQuestion == 0
                        ? "question-option-2 question-option-mobile-2"
                        : ""
                    }`}
                    sx={{
                      border: "1px solid #3397DB",
                      fontWeight: "600",
                      borderRadius: "20px",
                      padding: "5px 15px 5px 15px",
                      display: "flex",
                      alignItems: "flex-end",
                      color: "black",
                      backgroundColor: "#F2F8FD ",
                      fontSize: "14px",
                      lineHeight: "19px",
                      cursor: "pointer",
                    }}
                  >
                    {containerId == "6" ? questionDetail?.time_taken_for_answer : time}

                  </Box>
                </>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display:
                (containerId === "6" || containerId === "10"||containerId=="11") &&
                isAllQuestionAttempt === false
                  ? "flex"
                  : "none",
              justifyContent: "space-between",
              alignItems: "flex-start",
              flexDirection: "column",
              width: "100%",
              height: "100%",
              gap: "15px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Box>
                <Box
                  sx={{
                    fontSize: { xs: "24px", md: "18px" },
                    fontWeight: { xs: "700", md: "600" },
                    color: color,
                  }}
                >
                  {" "}
                  {containerId == "6" || containerId == "10"||containerId=="11" ? (
                    container_data?.display_name
                  ) : (
                    <FormattedMessage id="examines-analogies" />
                  )}
                  {/* {(containerId == "10"||containerId=="11") && isAllQuestionAttempt == false && (
                    <>({student_level})</>
                  )} */}
                </Box>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: 400,
                    color: color,
                    mt: "5%",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {" "}
                  {containerId == "10" || containerId == "11" ? <FormattedMessage id="cluster-time:" /> : <FormattedMessage id="exam-time:" />}
                  {container_data?.total_examtime}{" "}
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  color: color,
                }}
              >
                <FormattedMessage
                  id="questionInfo"
                  values={{
                    totalQuestions: questions.length,
                    currentQuestion,
                  }}
                />{" "}
                {containerId === "6" && (
                  <>
                    ({score} <FormattedMessage id="points" />)
                  </>
                )}{" "}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "5px",
                  fontSize: "16px",
                  fontWeight: 400,
                  color: color,
                  // mt: "5%",
                }}
              >
                <span>
                  {" "}
                  <Box
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: color,
                      display: { xs: "none", md: "block" },
                    }}
                  >
                    {" "}
                    {containerId == "10" || containerId == "11" ? <FormattedMessage id="cluster-time:" /> : <FormattedMessage id="exam-time:" />}
                    {container_data?.total_examtime}{" "}
                  </Box>
                </span>

                <FormattedMessage id="have-passed" />

                <Box
                  sx={{
                    width: "70px",
                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {showTime ? (
                    <Box
                      onClick={handleTimeClick}
                      sx={{
                        border: "1px solid #3397DB",
                        fontWeight: "600",
                        borderRadius: "20px",
                        padding: "5px 15px 5px 15px",
                        display: "flex",
                        alignItems: "flex-end",
                        color: "black",
                        backgroundColor: "#F2F8FD ",
                        fontSize: "14px",
                        lineHeight: "19px",
                        cursor: "pointer",
                      }}
                    >
                      {time}
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 0,
                      }}
                    >
                      {renderImg === true ? (
                        <img
                          className="library-item-img"
                          src={TimerIcon}
                          alt="TimerIcon"
                          style={{
                            cursor: "pointer",

                            height: "20px",
                            width: "20px",
                          }}
                          onClick={handleTimerIconClick}
                        />
                      ) : (
                        <WhiteTimerIcon
                          onClick={handleTimerIconClick}
                          style={{
                            cursor: "pointer",

                            height: "20px",
                            width: "20px",
                          }}
                        />
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          {collection.length === 0 ? (
            <></>
          ) : (
            <>
              {isAllQuestionAttempt === true ? (
                <Box sx={{ display: { xs: "flex", md: "none" } }}>
                  {isCollapsed ? null : (
                    <Box
                      sx={{
                        maxWidth: "724px",
                        width: { xs: "100%", md: "95%" },
                        height: "auto",
                        flexDirection: "column",
                        gap: "15px",
                        display: "flex",
                        alignItems:
                          containerId == 6 || containerId == 10||containerId==11
                            ? "flex-start"
                            : "center",
                      }}
                    >
                      <Box
                        sx={{
                          marginLeft: {
                            xs: "0px",
                            md:
                              containerId === "7" || containerId === "8"
                                ? "270px"
                                : "auto",
                          },
                        }}
                      >
                        <Typography
                          key={text?.id}
                          variant="h4"
                          sx={{
                            marginBottom: "10px",
                            display: "block",
                            fontWeight: 700,
                            fontSize: "20px",
                          }}
                        >
                          {text ? (
                            <FormattedMessage id={text} />
                          ) : (
                            <FormattedMessage id="readingPart" />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: color,
                            padding: "5px",
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: collection[0].cover,
                            }}
                          ></span>
                        </Typography>
                      </Box>
                      <Stack
                        spacing={{ xs: 1, sm: 2, md: 2 }}
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                        sx={{
                          borderRadius: { xs: "5px", md: "20px" },
                          padding: { xs: "5px", md: "25px" },
                          backgroundColor: "#ffffff",
                          height: {
                            xs: "auto",
                            md: "100%",
                          },
                        }}
                      >
                        {collection.length === 0 ? (
                          <>
                            <span>No data Available</span>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                maxHeight: { md: "500px" },
                                overflow: "auto",
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: collection[0].cover,
                                }}
                              ></span>
                              <img
                                style={{ height: "auto", width: "100%" }}
                                src={getImageUrl(
                                  collection[0].file.file_path,
                                  ""
                                )}
                                alt=""
                              />
                            </Box>
                          </>
                        )}
                      </Stack>
                    </Box>
                  )}
                  <Box
                    sx={{
                      display: isCollapsed
                        ? { xs: "none", md: "flex" }
                        : { xs: "none", md: "flex" },
                      alignItems: "center",
                      marginBottom: "10px",
                      zIndex: "1",
                      position: "absolute",
                      right: 0,
                      backgroundColor: isCollapsed ? "#ffff" : "#E8EBED",
                      top: 0,
                      width: "55px",
                      height: "100vh",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: 0,
                      }}
                    >
                      <CollapseIcon
                        className="library-item-img"
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: "20px",
                          left: "20px",
                        }}
                        onClick={handleIconClick}
                      />
                    </Box>

                    <Box>
                      <Typography
                        key={text?.id}
                        variant="h4"
                        sx={{
                          height: isCollapsed ? "24px" : "24px",
                          fontSize: isCollapsed ? "16px" : "16px",
                          fontWeight: "700",
                          transform: "rotate(270deg)",
                          marginTop: "57px",
                          display: isCollapsed ? "block" : "block",
                          position: "absolute",
                          width:
                            isCollapsed && textLength > 10 ? "300px" : "98px",
                          right:
                            isCollapsed && textLength > 10 ? "-127px" : "-25px",
                          top:
                            isCollapsed && textLength > 10 ? "130px" : "30px",
                          color: "black",
                        }}
                      >
                        {isCollapsed ? (
                          <>
                            {text ? (
                              <FormattedMessage id={text} />
                            ) : (
                              <FormattedMessage id="readingPart" />
                            )}
                          </>
                        ) : (
                          <>
                            <FormattedMessage id="readingPart1" />
                          </>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <>
                  <Box
                    sx={{
                      maxWidth: "724px",
                      width: { xs: "100%", md: "95%" },
                      height: "auto",
                      flexDirection: "column",
                      gap: "15px",
                      display: { xs: "flex", md: "none" },
                      alignItems:
                        containerId == 6 || containerId == 10||containerId==11
                          ? "flex-start"
                          : "center",
                      marginTop: "0px",
                      marginBottom: "0",
                    }}
                  >
                    <Box
                      sx={{
                        marginTop: "19px",
                        marginLeft: {
                          xs: "0px",
                          md:
                            containerId === "7" || containerId === "8"
                              ? "270px"
                              : "auto",
                        },
                      }}
                    >
                      <Typography
                        key={text?.id}
                        variant="h4"
                        sx={{
                          marginBottom: "10px",
                          display: "block",
                          fontWeight: 700,
                          color: textColor,
                          fontSize: "20px",
                        }}
                      >
                        {text ? (
                          <FormattedMessage id={text} />
                        ) : (
                          <FormattedMessage id="readingPart" />
                        )}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 400,
                          color: color,
                        }}
                      >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: collection[0].cover,
                          }}
                        ></span>
                      </Typography>
                    </Box>

                    <Stack
                      spacing={{ xs: 1, sm: 2, md: 2 }}
                      direction="row"
                      useFlexGap
                      flexWrap="wrap"
                      sx={{
                        borderRadius: { xs: "5px", md: "20px" },
                        padding: { xs: "5px", md: "25px" },
                        backgroundColor: "#ffffff",
                        height: {
                          xs: "auto",
                          md: "100%",
                        },
                      }}
                    >
                      {collection.length === 0 ? (
                        <>
                          <span>No data Available</span>
                        </>
                      ) : (
                        <>
                          <Box
                            sx={{
                              maxHeight: { md: "500px" },
                              overflow: "auto",
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: collection[0].cover,
                              }}
                            ></span>

                            <img
                              style={{ height: "auto", width: "100%" }}
                              src={getImageUrl(
                                collection[0]?.explanation_image?.file_path,
                                ""
                              )}
                              alt=""
                            />
                          </Box>
                        </>
                      )}
                    </Stack>
                  </Box>
                </>
              )}
            </>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              backgroundColor:
                containerId === "7" || containerId === "8"
                  ? "#ffffff"
                  : isAllQuestionAttempt !== true
                  ? "#ffffff"
                  : "transparent",
              borderRadius: { xs: "5px", md: "20px" },
              padding: { xs: "5px", md: "25px" },
              minHeight: "300px",
              overflowY: { xs: "inherit", md: "auto" },
              overflowX: "hidden",
              marginBottom: { xs: "10px", md: "0" },
              flexBasis: "100%",
            }}
            ref={questionRef}
          >
            <Typography
              variant="body1"
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: question,
                  }}
                />
              </>
            </Typography>
            <FormControl>
              <Box sx={{ display: "flex", height: "80px", width: "88px" }}>
                {image && (
                  <img
                    src={getImageUrl(image?.file_path, "")}
                    alt={
                      image.file_name.substring(
                        0,
                        image.file_name.lastIndexOf(".")
                      ) || image.file_name
                    }
                  />
                )}
              </Box>

              <Box
                sx={{
                  display:
                    horizontal === 1 ? { xs: "block", sm: "grid" } : "block",
                  gridTemplateColumns: horizontal === 1 ? "repeat(2, 1fr)" : "",
                  gap: "30px",
                }}
              >
                {option_images.map((option: any, i: any) => {
                  return (
                    <RadioGroup name="text-radio">
                      <Grid
                        container
                        style={{
                          marginBottom: "10px",
                          alignItems: horizontal === 1 ? "" : "center",
                        }}
                      >
                        {isAllQuestionAttempt === false ? (
                          <>
                            <QuestionOption
                              value={option.id}
                              onClick={() => handleSelectOption(option.id)}
                              selected={option_id == option.id}
                            >
                              {i + 1}
                            </QuestionOption>
                            <img
                              src={getImageUrl(option.file_path, "")}
                              height={50}
                              alt="file_path"
                            />
                          </>
                        ) : (
                          <>
                            {isAllQuestionAttempt === true ? (
                              (option.is_correct === 0 &&
                                option.id === option_id) ||
                              (option.is_correct === 0 &&
                                correctAnswer[0]?.answer == option.id) ? (
                                <Close
                                  color="error"
                                  sx={{
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              ) : option.is_correct === 1 ? (
                                <Check
                                  color="success"
                                  sx={{
                                    height: "15px",
                                    width: "15px",
                                  }}
                                />
                              ) : (
                                <Close
                                  color="success"
                                  sx={{
                                    height: "15px",
                                    width: "15px",
                                    opacity: "0",
                                  }}
                                />
                              )
                            ) : (
                              <Close
                                color="success"
                                sx={{
                                  height: "15px",
                                  width: "15px",
                                  opacity: "0",
                                }}
                              />
                            )}
                            <QuestionOption
                              value={option.id}
                              selected={correctAnswer[0]?.answer == option.id}
                            >
                              {i + 1}
                            </QuestionOption>
                            <img
                              src={getImageUrl(option.file_path, "")}
                              height={50}
                              alt="filepath"
                            />
                          </>
                        )}
                      </Grid>
                    </RadioGroup>
                  );
                })}
              </Box>
            </FormControl>
            {showResultMode || isAllQuestionAttempt ? (
              <>
                <DescriptionBox
                  description={solving_explanation}
                  file_path={explanation_image?.file_path}
                />
                <Box sx={{ display: "flex", justifyContent: "end" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#4A5056",
                      marginTop: "-8px",
                    }}
                  >
                    ID:
                    <span
                      style={{
                        marginLeft: "5px",
                        fontSize: "14px",
                        color: "#4A5056",
                      }}
                    >
                      {question_id}
                    </span>
                  </Typography>
                </Box>
              </>
            ) : (
              <></>
            )}
            <Box
              display={{
                xs:
                  containerId === "7" || containerId === "8"
                    ? "none"
                    : atomicSideBar === false || atomicSideBar === null
                    ? "flex"
                    : "none",
                md:
                  atomicSideBar === false || atomicSideBar === null
                    ? "flex"
                    : "none",
              }}
              sx={{
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isAllQuestionAttempt === true ? (
                <></>
              ) : (
                <Button
                  variant="contained"
                  disabled={(containerId == 1 || (containerId == 7&&!is_cluster)||containerId == 8)?notSubmitted:notSubmitted&&currentQuestion == lastIndex + 1}
                  sx={{
                    backgroundColor: "7A49B9",
                    my: "15px",
                    width: "152px",
                    height: "40px",
                    padding: "9px 22px",
                    borderRadius: "8px",
                  }}
                  onClick={() =>
                    onSubmit({
                      user_id: user_id,
                      container_id: searchParams.get("container_id"),
                      question_id: question_id,
                      answer: option_id,
                      time_taken_for_answer: differenceValue,
                    })
                  }
                >
                  <FormattedMessage
                    id={
                      currentQuestion == lastIndex + 1 && containerId != 1
                        ? "done"
                        : containerId == 1 || containerId == 7
                        ? "check-answer"
                        : "next-question"
                    }
                  />
                </Button>
              )}
            </Box>
          </Box>
        </Box>
        {(containerId === "7" || containerId === "8") && (
          <Box
            sx={{
              display: { xs: "flex", md: "none" },
              width: ["-webkit-fill-available", "-moz-available"],
              paddingBottom: "0px",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "15px",
              background: "var(--White, #FFF)",
              height: "65px",
              position: "fixed",
              bottom: "0px",
            }}
          >
            {isAllQuestionAttempt === true ? (
              <></>
            ) : (
              <Button
                variant="contained"
                disabled={(containerId == 1 || (containerId == 7&&!is_cluster)||containerId == 8)?notSubmitted:notSubmitted&&currentQuestion == lastIndex + 1}
                sx={{
                  backgroundColor: "7A49B9",
                  my: "15px",
                  width: "152px",
                  height: "40px",
                  padding: "9px 22px",
                  borderRadius: "8px",
                }}
                onClick={() =>
                  onSubmit({
                    user_id: user_id,
                    container_id: searchParams.get("container_type_id"),
                    question_id: question_id,
                    answer: option_id,
                    time_taken_for_answer: differenceValue,
                  })
                }
              >
                <FormattedMessage
                  id={
                    currentQuestion == lastIndex + 1 && containerId != 1
                      ? "done"
                      : containerId == 1 || containerId == 7
                      ? "check-answer"
                      : "next-question"
                  }
                />
              </Button>
            )}
          </Box>
        )}
        {!isSmallScreen ? (
          <>
            {collection.length === 0 ? (
              <></>
            ) : (
              <>
                {isAllQuestionAttempt === true ? (
                  <>
                    {isCollapsed ? null : (
                      <Box
                        sx={{
                          maxWidth: "724px",
                          width: { xs: "100%", md: "95%" },
                          height: "auto",
                          flexDirection: "column",
                          gap: "15px",
                          display: "flex",
                          alignItems:
                            containerId == 6 || containerId == 10 ||containerId==11
                              ? "flex-start"
                              : "center",
                        }}
                      >
                        <Box
                          sx={{
                            marginLeft: {
                              xs: "0px",
                              md:
                                containerId === "7" || containerId === "8"
                                  ? "270px"
                                  : "auto",
                            },
                          }}
                        >
                          <Typography
                            key={text?.id}
                            variant="h4"
                            sx={{
                              marginBottom: "5px",
                              display: "block",
                              fontWeight: 700,
                              fontSize: "20px",
                            }}
                          >
                            {text ? (
                              <FormattedMessage id={text} />
                            ) : (
                              <FormattedMessage id="readingPart" />
                            )}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: 400,
                              color: color,
                              padding: "5px",
                            }}
                          >
                            <span
                              dangerouslySetInnerHTML={{
                                __html: collection[0].cover,
                              }}
                            ></span>
                          </Typography>
                        </Box>
                        <Stack
                          spacing={{ xs: 1, sm: 2, md: 2 }}
                          direction="row"
                          useFlexGap
                          flexWrap="wrap"
                          sx={{
                            borderRadius: { xs: "5px", md: "20px" },
                            padding: { xs: "5px", md: "25px" },
                            backgroundColor: "#ffffff",
                            height: {
                              xs: "auto",
                              md: "100%",
                            },
                          }}
                        >
                          {collection.length === 0 ? (
                            <>
                              <span>No data Available</span>
                            </>
                          ) : (
                            <>
                              <Box
                                sx={{
                                  maxHeight: { md: "500px" },
                                  overflow: "auto",
                                }}
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: collection[0].cover,
                                  }}
                                ></span>
                                <img
                                  style={{ height: "auto", width: "100%" }}
                                  src={getImageUrl(
                                    collection[0]?.explanation_image?.file_path,
                                    ""
                                  )}
                                  alt=""
                                />
                              </Box>
                            </>
                          )}
                        </Stack>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: isCollapsed
                          ? { xs: "none", md: "flex" }
                          : { xs: "none", md: "flex" },
                        alignItems: "center",
                        marginBottom: "10px",
                        zIndex: "0",
                        position: "absolute",
                        right: 0,
                        backgroundColor: isCollapsed ? "#ffff" : "#E8EBED",
                        height: "100%",
                        top: 0,
                        width: "55px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginRight: 0,
                        }}
                      >
                        <CollapseIcon
                          className="library-item-img"
                          style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: "20px",
                            left: "20px",
                          }}
                          onClick={handleIconClick}
                        />
                      </Box>

                      <Box>
                        <Typography
                          key={text?.id}
                          variant="h4"
                          sx={{
                            height: isCollapsed ? "24px" : "24px",
                            fontSize: isCollapsed ? "16px" : "16px",
                            fontWeight: "700",
                            transform: "rotate(270deg)",
                            marginTop: "57px",
                            display: isCollapsed ? "block" : "block",
                            position: "absolute",
                            width:
                              isCollapsed && textLength > 10 ? "300px" : "98px",
                            right:
                              isCollapsed && textLength > 10
                                ? "-127px"
                                : "-25px",
                            top:
                              isCollapsed && textLength > 10 ? "130px" : "30px",
                            color: "black",
                          }}
                        >
                          {isCollapsed ? (
                            <>
                              {text ? (
                                <FormattedMessage id={text} />
                              ) : (
                                <FormattedMessage id="readingPart" />
                              )}
                            </>
                          ) : (
                            <>
                              <FormattedMessage id="readingPart1" />
                            </>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                  </>
                ) : (
                  <>
                    <Box
                      sx={{
                        maxWidth: "724px",
                        width: { xs: "100%", md: "95%" },
                        height: "auto",
                        flexDirection: "column",
                        gap: "15px",
                        display: { xs: "none", md: "flex" },
                        alignItems:
                          containerId == 6 || containerId == 10||containerId==11
                            ? "flex-start"
                            : "center",
                        marginBottom: "0",
                      }}
                    >
                      <Box
                        sx={{
                          marginTop: "19px",
                          marginLeft: {
                            xs: "0px",
                            md:
                              containerId === "7" || containerId === "8"
                                ? "270px"
                                : "auto",
                          },
                        }}
                      >
                        <Typography
                          key={text?.id}
                          variant="h4"
                          sx={{
                            marginBottom: "5px",
                            display: "block",
                            fontWeight: 700,
                            color: textColor,
                            fontSize: "20px",
                          }}
                        >
                          {text ? (
                            <FormattedMessage id={text} />
                          ) : (
                            <FormattedMessage id="readingPart" />
                          )}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: 400,
                            color: color,
                          }}
                        >
                          <span
                            dangerouslySetInnerHTML={{
                              __html: collection[0].cover,
                            }}
                          ></span>
                        </Typography>
                      </Box>

                      <Stack
                        spacing={{ xs: 1, sm: 2, md: 2 }}
                        direction="row"
                        useFlexGap
                        flexWrap="wrap"
                        sx={{
                          borderRadius: { xs: "5px", md: "20px" },
                          padding: { xs: "5px", md: "25px" },
                          backgroundColor: "#ffffff",
                          height: {
                            xs: "auto",
                            md: "100%",
                          },
                        }}
                      >
                        {collection.length === 0 ? (
                          <>
                            <span>No data Available</span>
                          </>
                        ) : (
                          <>
                            <Box
                              sx={{
                                maxHeight: { md: "500px" },
                                overflow: "auto",
                              }}
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: collection[0].cover,
                                }}
                              ></span>

                              <img
                                style={{ height: "auto", width: "100%" }}
                                src={getImageUrl(filePath, "")}
                                alt=""
                              />
                            </Box>
                          </>
                        )}
                      </Stack>
                    </Box>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <></>
        )}
        <Dialog
          open={questionModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth={"lg"}
          PaperProps={{
            sx: { borderRadius: "30px" },
          }}
          ref={(node: any) => {
            questionDialogRef.current = node;

            questionDialogRef.current &&
              (window as any).com.wiris.js.JsPluginViewer.parseElement(
                questionDialogRef.current,
                true,
                function () {}
              );
          }}
        >
          <Box
            sx={{
              padding: "25px",
              paddingBottom: "35px",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Button sx={{ justifyContent: "end" }}>
              <Close onClick={handleClosePopup} />
            </Button>
            <Box
              sx={{
                width: "100%",
                paddingBottom: {
                  xs:
                    containerId === "7" || containerId === "8" ? "20" : "10px",
                  md: "10px",
                },
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: 100,
                  textAlign: { xs: "right", md: "right" },
                  color: "#878E95",
                }}
              >
                <FormattedMessage
                  id="questionInfo"
                  values={{
                    totalQuestions: questions.length,
                    currentQuestion,
                  }}
                />
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "column",
                  lg: "row",
                },
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: { xs: "95%", sm: "95%", md: "95%", lg: "50%" },
                  height: "auto",
                  backgroundColor: "#ffffff",
                  flexDirection: "column",
                  borderTop: "1px solid #E6E6E6",
                  padding: { xs: "", sm: "", md: "", lg: "25px" },
                }}
              >
                <Box
                  sx={{ display: "flex", flexDirection: "column", gap: "20px" }}
                >
                  <Typography variant="body1">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: question,
                      }}
                    />
                  </Typography>

                  <FormControl>
                    <QuestionAttemptAndCheck
                      options={options}
                      handleSelection={handleSelectOption}
                    />
                  </FormControl>

                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    style={
                      atomicSideBar === false || atomicSideBar === null
                        ? { display: "flex" }
                        : { display: "none" }
                    }
                  >
                    {isAllQuestionAttempt === true ? (
                      <></>
                    ) : (
                      <Button
                        variant="contained"
                        disabled={(containerId == 1 || (containerId == 7&&!is_cluster)||containerId == 8)?notSubmitted:notSubmitted&&currentQuestion == lastIndex + 1}
                        sx={{
                          backgroundColor: "7A49B9",
                          my: "15px",
                          width: "152px",
                          height: "40px",
                          padding: "9px 22px",
                          borderRadius: "8px",
                        }}
                        onClick={() =>
                          onSubmit({
                            user_id: user_id,
                            container_id: searchParams.get("container_id"),
                            question_id: question_id,
                            answer: option_id,
                            time_taken_for_answer: differenceValue,
                          })
                        }
                      >
                        <FormattedMessage id="next-question" />
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: { xs: "95%", sm: "95%", md: "95%", lg: "50%" },
                    height: "auto",
                    backgroundColor: "#ffffff",
                    flexDirection: "column",
                    borderTop: "1px solid #E6E6E6",
                    padding: { xs: "", sm: "", md: "", lg: "25px" },
                  }}
                >
                  {showResultMode || isAllQuestionAttempt ? (
                    <>
                      <DescriptionBox
                        description={solving_explanation}
                        file_path={explanation_image?.file_path}
                        view_column={true}
                      />
                      <Box sx={{ display: "flex", justifyContent: "end" }}>
                        <Typography sx={{ fontSize: "14px", color: "#4A5056" }}>
                          ID:
                          <span
                            style={{
                              marginLeft: "5px",
                              fontSize: "14px",
                              color: "#4A5056",
                            }}
                          >
                            {question_id}
                          </span>
                        </Typography>
                      </Box>
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Box>{collection.length === 0 ? <></> : <></>}</Box>
            </Box>
          </Box>
        </Dialog>
      </Grid>

      {isDialogOpen &&
        isSmallScreen &&
        !renderPreview &&
        !isAllQuestionAttempt &&
        collection.length != 0 && (
          <Dialog
            open={isDialogOpen}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: { borderRadius: "30px" },
            }}
          >
            <Box
              style={{
                padding: "15px",
                paddingTop: "25px",
                paddingBottom: "35px",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
              }}
            >
              <Button style={{ justifyContent: "end" }}>
                <Close onClick={handleCloseDialog} />
              </Button>
              <DialogContent style={{ padding: 0 }}>
                <DialogContentText id="alert-dialog-description">
                  <Typography
                    style={{
                      color: "#222529",
                      textAlign: "center",
                      fontWeight: "700",
                      fontSize: "24px",
                    }}
                  >
                    <FormattedMessage id="you-should-open-this-screen-on-your-computer" />
                  </Typography>
                </DialogContentText>
              </DialogContent>
              <DialogActions
                style={{
                  display: "block",
                  paddingTop: "20px",
                  marginInline: "auto",
                }}
              >
                <Stack direction="row" justifyContent="center" width="100%">
                  <Button
                    onClick={handleCloseDialog}
                    autoFocus
                    variant="contained"
                    style={{
                      border: "1px solid #7A49B9",
                      marginInline: "5px",
                    }}
                  >
                    <FormattedMessage id="continue-anyway" />
                  </Button>
                  <Button
                    onClick={nextQuestion}
                    style={{
                      border: "1px solid #7A49B9",
                      marginInline: "5px",
                    }}
                  >
                    <FormattedMessage id="skip-this-task" />
                  </Button>
                </Stack>
              </DialogActions>
            </Box>
          </Dialog>
        )}
    </>
  );
};

export default TextImageWithQuestion;
